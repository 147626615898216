<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        User
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row search-form">
                                    
                                        <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="From Date" v-model="filter.fromDate" class="form-control" >
                                        </div>
                                        <div class="col-md-3 mt-1">
                                            <input onfocus="(this.type='date')" placeholder="To Date" v-model="filter.toDate" class="form-control" >
                                        </div>
                                        <div class="col-md-3 mt-1">
                                            <input type="text" v-model="filter.name" class="form-control" placeholder="Name">
                                        </div>
                                        <div class="col-md-3 mt-1" v-if="role != 'cb'">
                                            <select class="form-control" v-model="filter.type">
                                                <option value="">All Users</option>
                                                <option value="hohodidi_user">Hohodidi User</option>
                                                <option value="cb_user">CB User</option>
                                            </select>
                                        </div>
                                    <div class="col-md-3 mt-1">
                                        <button class="btn btn-primary mr-1 fbutton" @click="search()"><i class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-info fbutton" @click="reset()"><i class="fas fa-sync"></i> Reset</button>
                                        <button class="btn btn-success fbutton mt-1" @click="memberExport()"><i class="fas fa-csv"></i> Export</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="th-width-100" style="display: none;">Image</th>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-200">Point</th>
                                                <th class="th-width-200">CB Point</th>
                                                <th class="th-width-200">Role</th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td style="display: none;"><img :src="env.mediaUrl+data.image" class="tbl_image"/></td>
                                                <td>{{data.name}}</td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.phone}}</td>
                                                <td>{{data.date}}</td>
                                                <td>{{data.point}}</td>
                                                <td>{{data.cbPoint}}</td>
                                                <td>{{data.role}}</td>
                                                <td>
                                                    <button class="btn btn-option text-primary"
                                                        @click="editData(data)" v-if="checkPermission('userEdit') == true"><i class="fas fa-edit"></i></button>
                                                    <!-- <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger"><i
                                                            class="fas fa-trash-alt" @click="deleteData(data)"></i></button> -->
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="6" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-account"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-account" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-account"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteAccount()">Delete</b-button>
        </b-modal>
        <router-link v-if="checkPermission('userAdd') == true" class="float" to="member-create">
            <i class="fa fa-plus my-float" style="padding: 37%;"></i>
        </router-link>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'accountList',
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                dataList: [],
                role: "",
                permissions: "",
                columns: {
                    name: "Name",
                    email: "Email",
                    extra_phone: "Phone",
                    created_at: "Date"
                },
                filter: {
                    page: 1,
                    reverse: "asc",
                    sort: "created_at",
                    name: null,
                    fromDate: "",
                    toDate: "",
                    type: ""
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                }
            }
        },
        watch: {
        '$route' (to, from) {
            this.fetchData()
        }
    },
        computed: {},
        methods: {
            ...mapActions({
                memberListAction: 'memberListAction',
                memberDeleteAction: 'memberDeleteAction',
                memberExportAction: 'memberExportAction'
            }),
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
                this.$refs['my-modal'].hide()
            },
            async memberExport() {
                this.filter.page = 1
   
                this.isLoading = true
                let option = this.filter
                await this.memberExportAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        const url = env.mediaUrl+"/"+res.data.data.downlodPath;
                        console.log(url)
                        window.location.href = url;
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async fetchData() {
                this.isLoading = true
                if(this.role == 'cb')
                {
                    this.filter.type = 'cb_user';
                }
                let option = {
                    name: this.filter.name,
                    sort: this.filter.sort,
                    reverse: this.filter.reverse,
                    fromDate: this.filter.fromDate,
                    toDate: this.filter.toDate,
                    page: this.filter.page,
                    type: this.filter.type
                }
                await this.memberListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({ path: 'member-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, name: this.filter.name, fromDate: this.filter.fromDate, toDate: this.filter.toDate} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'member-list', query: {page: 1, reverse: 'desc', sort: 'created_at', name: ''} }).catch(()=>{})
                this.$router.go()
            },
            editData(data) {
                this.$router.replace({ path: 'member-edit', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, name: this.filter.name, id: data.id, fromDate: this.filter.fromDate, toDate: this.filter.toDate } }).catch(()=>{})
            },
            deleteData(data){
                this.id = data.id
            },
            async deleteAccount() {
				this.isLoading = true
				let option = {
					id: this.id,
				}
				await this.memberDeleteAction({
					...option
				}).then(res => {
					this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            checkPermission: function (key) {
                if(key in this.permissions){
                    return true
                }else{
                    return false
                }
            }

        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.permissions = user.permissions
            this.role = user.role
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.name = this.$route.query.name
            this.filter.fromDate = this.$route.query.fromDate
            this.filter.toDate = this.$route.query.toDate
            this.fetchData()
        }
    }
</script>

<style scoped lang="scss">
    // .action {
    //     border: none;
    //     background: none;
    //     font-size: 24px;
    // }
    .btn-option {
    border: none;
    background: none;
    font-size: 14px !important; 
    }
</style>